
/* Our service title */
.cid-rys5Ncjm3O {
    padding-top: 75px;
    padding-bottom: 15px;
    background-color: #ffffff;
}
.cid-rys5Ncjm3O .line {
    background-color: #232323;
    color: #232323;
    align: center;
    height: 2px;
    margin: 0 auto;
}
.cid-rys5Ncjm3O .section-text {
    padding: 2rem 0;
    color: #232323;
}
.cid-rys5Ncjm3O .inner-container {
    margin: 0 auto;
}
@media (max-width: 768px) {
    .cid-rys5Ncjm3O .inner-container {
      width: 100% !important;
    }
}

/* OUR SERVICE CONTENT */
.cid-runYY9kxkA {
    padding-top: 30px;
    padding-bottom: 15px;
    background-color: #ffffff;
  }
.cid-runYY9kxkA h4 {
    font-weight: 500;
    color: #ffffff;
}
.cid-runYY9kxkA p {
    color: #ffffff;
}
.cid-runYY9kxkA .card {
    margin-bottom: 2rem;
}
.cid-runYY9kxkA .card-img {
    border-radius: 0;
    padding: 2rem 2rem 0 2rem;
}
.cid-runYY9kxkA .card-img span {
    font-size: 100px;
    color: #ffffff;
}
.cid-runYY9kxkA .card-box {
    padding-bottom: 2rem;
}
.cid-runYY9kxkA .card-box .card-title {
    margin: 0;
    padding: 2rem 2rem 0 2rem;
}
.cid-runYY9kxkA .card-box .mbr-text {
    margin-bottom: 0;
    padding: 2rem 2rem 0 2rem;
}
.cid-runYY9kxkA .card-box .mbr-section-btn {
    padding-top: 1rem;
}
.cid-runYY9kxkA .card-box .mbr-section-btn a {
    margin-top: 1rem;
    margin-bottom: 0;
}
.cid-runYY9kxkA P {
    color: #232323;
}
.cid-runYY9kxkA .card-title,
.cid-runYY9kxkA .card-img {
    color: #232323;
}

.ourservice-icon {
    color: rgb(35, 35, 35) !important;
    fill: rgb(35, 35, 35) !important;
}

/* OUR CLIENTS TITLE */
.cid-rys5wxOGGv {
    padding-top: 45px;
    padding-bottom: 0px;
    background-color: #ffffff;
  }
  .cid-rys5wxOGGv .line {
    background-color: #232323;
    color: #232323;
    align: center;
    height: 2px;
    margin: 0 auto;
  }
  .cid-rys5wxOGGv .section-text {
    padding: 2rem 0;
    color: #232323;
  }
  .cid-rys5wxOGGv .inner-container {
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    .cid-rys5wxOGGv .inner-container {
      width: 100% !important;
    }
  }

/* OUR CLIENTS CONTENT */
.cid-ryrt7UAZ4N {
    padding-top: 0px;
    padding-bottom: 15px;
    background-color: #ffffff;
  }
  .cid-ryrt7UAZ4N .mbr-slider .carousel-control {
    background: #1b1b1b;
  }
  .cid-ryrt7UAZ4N .mbr-slider .carousel-control-prev {
    left: 0;
    margin-left: 2.5rem;
  }
  .cid-ryrt7UAZ4N .mbr-slider .carousel-control-next {
    right: 0;
    margin-right: 2.5rem;
  }
  .cid-ryrt7UAZ4N .mbr-slider .modal-body .close {
    background: #1b1b1b;
  }
  .cid-ryrt7UAZ4N .mbr-gallery-item > div::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #232323;
    opacity: 0;
    -webkit-transition: 0.2s opacity ease-in-out;
    transition: 0.2s opacity ease-in-out;
  }
  .cid-ryrt7UAZ4N .mbr-gallery-item > div:hover .mbr-gallery-title::before {
    background: transparent !important;
  }
  .cid-ryrt7UAZ4N .mbr-gallery-item > div:hover:before {
    opacity: 0.7 !important;
  }
  .cid-ryrt7UAZ4N .mbr-gallery-title {
    font-size: .9em;
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    padding: 1rem;
    color: #fff;
    z-index: 2;
  }
  .cid-ryrt7UAZ4N .mbr-gallery-title:before {
    content: " ";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    background: #232323 !important;
    opacity: 0.7;
    -webkit-transition: 0.2s background ease-in-out;
    transition: 0.2s background ease-in-out;
  }
