.cid-career {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff;
}
.cid-font-career-apply {
    font-size: 35px;
    color: #a2d729;
}
.cid-acordion-color {
    background-color: #a2d729;
}
.cid-career-btn {
    background-color: #a2d729;
    color: #ffffff
}
.cid-career-label {
    font-size: 18px;
    padding-left: 10px;
}
.cid-career-acordion-title {
    font-size: 18px;
    padding-left: 10px;
    color: #454545;
}
.cid-career-acordion-position {
    font-size: 20px;
    padding-left: 10px;
    text-transform: uppercase;
}
