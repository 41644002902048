.cid-portfolio {
    padding-top: 120px;
    padding-bottom: 60px;
    background-color: #ffffff;
}
.cid-portfolio .carousel-item {
    justify-content: center;
    -webkit-justify-content: center;
}
.cid-portfolio .carousel-item.active,
.cid-portfolio .carousel-item-next,
.cid-portfolio .carousel-item-prev {
    display: flex;
}
.cid-portfolio .carousel-controls {
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}
.cid-portfolio .carousel-controls .carousel-control {
    background: #000;
    border-radius: 50%;
    position: static;
    width: 40px;
    height: 40px;
    margin-top: 2rem;
    border-width: 1px;
}
.cid-portfolio .carousel-controls .carousel-control.carousel-control-prev {
    left: auto;
    margin-right: 20px;
    margin-left: 0;
}
.cid-portfolio .carousel-controls .carousel-control.carousel-control-next {
    right: auto;
    margin-right: 0;
}
.cid-portfolio .carousel-controls .carousel-control .mbr-iconfont {
    font-size: 1rem;
}