.cid-qTkzRZLJNu .navbar {
    padding: .5rem 0;
    background: #342e37;
    transition: none;
    min-height: 77px;
  }
  .cid-qTkzRZLJNu .navbar-dropdown.bg-color.transparent.opened {
    background: #342e37;
  }
  .cid-qTkzRZLJNu a {
    font-style: normal;
  }
  .cid-qTkzRZLJNu .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
  }
  .cid-qTkzRZLJNu .nav-item a {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
  }
  .cid-qTkzRZLJNu .nav-item:focus,
  .cid-qTkzRZLJNu .nav-link:focus {
    outline: none;
  }
  .cid-qTkzRZLJNu .btn {
    padding: 0.4rem 1.5rem;
    display: inline-flex;
    align-items: center;
  }
  .cid-qTkzRZLJNu .btn .mbr-iconfont {
    font-size: 1.6rem;
  }
  .cid-qTkzRZLJNu .menu-logo {
    margin-right: auto;
  }
  .cid-qTkzRZLJNu .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    align-items: center;
  }
  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-caption-wrap {
    display: -webkit-flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
  }
  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
  }
  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-logo {
    font-size: 4rem;
    transition: font-size 0.25s;
  }
  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
  }
  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
  }
  .cid-qTkzRZLJNu .navbar-toggleable-sm .navbar-collapse {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    padding-right: 5rem;
    width: auto;
  }
  .cid-qTkzRZLJNu .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
  }
  .cid-qTkzRZLJNu .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
  }
  .cid-qTkzRZLJNu .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
  }
  .cid-qTkzRZLJNu .dropdown .dropdown-menu {
    background: #342e37;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: center;
  }
  .cid-qTkzRZLJNu .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
  }
  .cid-qTkzRZLJNu .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
  }
  .cid-qTkzRZLJNu .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
  }
  .cid-qTkzRZLJNu .dropdown.open > .dropdown-menu {
    display: block;
  }
  .cid-qTkzRZLJNu .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
  }
  .cid-qTkzRZLJNu .navbar.navbar-short {
    min-height: 60px;
    transition: all .2s;
  }
  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-toggler-right {
    top: 20px;
  }
  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
  }
  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
  }
  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
  }
  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
  }
  .cid-qTkzRZLJNu button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
  }
  .cid-qTkzRZLJNu button.navbar-toggler:focus {
    outline: none;
  }
  .cid-qTkzRZLJNu button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #342e37;
  }
  .cid-qTkzRZLJNu button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
  }
  .cid-qTkzRZLJNu button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
  }
  .cid-qTkzRZLJNu button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
  }
  .cid-qTkzRZLJNu button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
  }
  .cid-qTkzRZLJNu nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
  }
  .cid-qTkzRZLJNu nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
  }
  .cid-qTkzRZLJNu nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
  }
  .cid-qTkzRZLJNu nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
  }
  .cid-qTkzRZLJNu .collapsed.navbar-expand {
    flex-direction: column;
  }
  .cid-qTkzRZLJNu .collapsed .btn {
    display: flex;
  }
  .cid-qTkzRZLJNu .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
  }
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing,
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.show {
    display: block !important;
  }
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing .navbar-nav,
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
  }
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
  }
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing .navbar-buttons,
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
  }
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
  .cid-qTkzRZLJNu .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
  }
  .cid-qTkzRZLJNu .collapsed button.navbar-toggler {
    display: block;
  }
  .cid-qTkzRZLJNu .collapsed .navbar-brand {
    margin-left: 1rem !important;
  }
  .cid-qTkzRZLJNu .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .cid-qTkzRZLJNu .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity,padding,height;
  }
  .cid-qTkzRZLJNu .collapsed .dropdown.open > .dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
  }
  .cid-qTkzRZLJNu .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
  }
  .cid-qTkzRZLJNu .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
  }
  @media (max-width: 991px) {
    .cid-qTkzRZLJNu .navbar-expand {
      flex-direction: column;
    }
    .cid-qTkzRZLJNu img {
      height: 3.8rem !important;
    }
    .cid-qTkzRZLJNu .btn {
      display: flex;
    }
    .cid-qTkzRZLJNu button.navbar-toggler {
      display: block;
    }
    .cid-qTkzRZLJNu .navbar-brand {
      margin-left: 1rem !important;
    }
    .cid-qTkzRZLJNu .navbar-toggleable-sm {
      flex-direction: column;
      -webkit-flex-direction: column;
    }
    .cid-qTkzRZLJNu .navbar-collapse {
      display: none !important;
      padding-right: 0 !important;
    }
    .cid-qTkzRZLJNu .navbar-collapse.collapsing,
    .cid-qTkzRZLJNu .navbar-collapse.show {
      display: block !important;
    }
    .cid-qTkzRZLJNu .navbar-collapse.collapsing .navbar-nav,
    .cid-qTkzRZLJNu .navbar-collapse.show .navbar-nav {
      display: block;
      text-align: center;
    }
    .cid-qTkzRZLJNu .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-qTkzRZLJNu .navbar-collapse.show .navbar-nav .nav-item {
      clear: both;
    }
    .cid-qTkzRZLJNu .navbar-collapse.collapsing .navbar-buttons,
    .cid-qTkzRZLJNu .navbar-collapse.show .navbar-buttons {
      text-align: center;
    }
    .cid-qTkzRZLJNu .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-qTkzRZLJNu .navbar-collapse.show .navbar-buttons:last-child {
      margin-bottom: 1rem;
    }
    .cid-qTkzRZLJNu .dropdown .dropdown-menu {
      width: 100%;
      text-align: center;
      position: relative;
      opacity: 0;
      display: block;
      height: 0;
      visibility: hidden;
      padding: 0;
      transition-duration: .5s;
      transition-property: opacity,padding,height;
    }
    .cid-qTkzRZLJNu .dropdown.open > .dropdown-menu {
      position: relative;
      opacity: 1;
      height: auto;
      padding: 1.4rem 0;
      visibility: visible;
    }
    .cid-qTkzRZLJNu .dropdown .dropdown-submenu {
      left: 0;
      text-align: center;
      width: 100%;
    }
    .cid-qTkzRZLJNu .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
      margin-top: 0;
      position: inherit;
      right: 0;
      top: 50%;
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .3em;
      vertical-align: middle;
      content: "";
      border-top: .30em solid;
      border-right: .30em solid transparent;
      border-left: .30em solid transparent;
    }
  }
  @media (min-width: 767px) {
    .cid-qTkzRZLJNu .menu-logo {
      flex-shrink: 0;
    }
  }
  .cid-qTkzRZLJNu .navbar-collapse {
    flex-basis: auto;
  }
  .cid-qTkzRZLJNu .nav-link:hover,
  .cid-qTkzRZLJNu .dropdown-item:hover {
    color: #f98533 !important;
  }

  .cid-rtVAO5iUaj .navbar {
    padding: .5rem 0;
    background: #342e37;
    transition: none;
    min-height: 77px;
  }
  .cid-rtVAO5iUaj .navbar-dropdown.bg-color.transparent.opened {
    background: #342e37;
  }
  .cid-rtVAO5iUaj a {
    font-style: normal;
  }
  .cid-rtVAO5iUaj .nav-item span {
    padding-right: 0.4em;
    line-height: 0.5em;
    vertical-align: text-bottom;
    position: relative;
    text-decoration: none;
  }
  .cid-rtVAO5iUaj .nav-item a {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0.7rem 0 !important;
    margin: 0rem .65rem !important;
  }
  .cid-rtVAO5iUaj .nav-item:focus,
  .cid-rtVAO5iUaj .nav-link:focus {
    outline: none;
  }
  .cid-rtVAO5iUaj .btn {
    padding: 0.4rem 1.5rem;
    display: inline-flex;
    align-items: center;
  }
  .cid-rtVAO5iUaj .btn .mbr-iconfont {
    font-size: 1.6rem;
  }
  .cid-rtVAO5iUaj .menu-logo {
    margin-right: auto;
  }
  .cid-rtVAO5iUaj .menu-logo .navbar-brand {
    display: flex;
    margin-left: 5rem;
    padding: 0;
    transition: padding .2s;
    min-height: 3.8rem;
    align-items: center;
  }
  .cid-rtVAO5iUaj .menu-logo .navbar-brand .navbar-caption-wrap {
    display: -webkit-flex;
    -webkit-align-items: center;
    align-items: center;
    word-break: break-word;
    min-width: 7rem;
    margin: .3rem 0;
  }
  .cid-rtVAO5iUaj .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption {
    line-height: 1.2rem !important;
    padding-right: 2rem;
  }
  .cid-rtVAO5iUaj .menu-logo .navbar-brand .navbar-logo {
    font-size: 4rem;
    transition: font-size 0.25s;
  }
  .cid-rtVAO5iUaj .menu-logo .navbar-brand .navbar-logo img {
    display: flex;
  }
  .cid-rtVAO5iUaj .menu-logo .navbar-brand .navbar-logo .mbr-iconfont {
    transition: font-size 0.25s;
  }
  .cid-rtVAO5iUaj .navbar-toggleable-sm .navbar-collapse {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    padding-right: 5rem;
    width: auto;
  }
  .cid-rtVAO5iUaj .navbar-toggleable-sm .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    padding-left: 0;
  }
  .cid-rtVAO5iUaj .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item {
    -webkit-align-self: center;
    align-self: center;
  }
  .cid-rtVAO5iUaj .navbar-toggleable-sm .navbar-collapse .navbar-buttons {
    padding-left: 0;
    padding-bottom: 0;
  }
  .cid-rtVAO5iUaj .dropdown .dropdown-menu {
    background: #342e37;
    display: none;
    position: absolute;
    min-width: 5rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    text-align: left;
  }
  .cid-rtVAO5iUaj .dropdown .dropdown-menu .dropdown-item {
    width: auto;
    padding: 0.235em 1.5385em 0.235em 1.5385em !important;
  }
  .cid-rtVAO5iUaj .dropdown .dropdown-menu .dropdown-item::after {
    right: 0.5rem;
  }
  .cid-rtVAO5iUaj .dropdown .dropdown-menu .dropdown-submenu {
    margin: 0;
  }
  .cid-rtVAO5iUaj .dropdown.open > .dropdown-menu {
    display: block;
  }
  .cid-rtVAO5iUaj .navbar-toggleable-sm.opened:after {
    position: absolute;
    width: 100vw;
    height: 100vh;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    z-index: 1000;
  }
  .cid-rtVAO5iUaj .navbar.navbar-short {
    min-height: 60px;
    transition: all .2s;
  }
  .cid-rtVAO5iUaj .navbar.navbar-short .navbar-toggler-right {
    top: 20px;
  }
  .cid-rtVAO5iUaj .navbar.navbar-short .navbar-logo a {
    font-size: 2.5rem !important;
    line-height: 2.5rem;
    transition: font-size 0.25s;
  }
  .cid-rtVAO5iUaj .navbar.navbar-short .navbar-logo a .mbr-iconfont {
    font-size: 2.5rem !important;
  }
  .cid-rtVAO5iUaj .navbar.navbar-short .navbar-logo a img {
    height: 3rem !important;
  }
  .cid-rtVAO5iUaj .navbar.navbar-short .navbar-brand {
    min-height: 3rem;
  }
  .cid-rtVAO5iUaj button.navbar-toggler {
    width: 31px;
    height: 18px;
    cursor: pointer;
    transition: all .2s;
    top: 1.5rem;
    right: 1rem;
  }
  .cid-rtVAO5iUaj button.navbar-toggler:focus {
    outline: none;
  }
  .cid-rtVAO5iUaj button.navbar-toggler .hamburger span {
    position: absolute;
    right: 0;
    width: 30px;
    height: 2px;
    border-right: 5px;
    background-color: #342e37;
  }
  .cid-rtVAO5iUaj button.navbar-toggler .hamburger span:nth-child(1) {
    top: 0;
    transition: all .2s;
  }
  .cid-rtVAO5iUaj button.navbar-toggler .hamburger span:nth-child(2) {
    top: 8px;
    transition: all .15s;
  }
  .cid-rtVAO5iUaj button.navbar-toggler .hamburger span:nth-child(3) {
    top: 8px;
    transition: all .15s;
  }
  .cid-rtVAO5iUaj button.navbar-toggler .hamburger span:nth-child(4) {
    top: 16px;
    transition: all .2s;
  }
  .cid-rtVAO5iUaj nav.opened .hamburger span:nth-child(1) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
  }
  .cid-rtVAO5iUaj nav.opened .hamburger span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all .25s;
  }
  .cid-rtVAO5iUaj nav.opened .hamburger span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .25s;
  }
  .cid-rtVAO5iUaj nav.opened .hamburger span:nth-child(4) {
    top: 8px;
    width: 0;
    opacity: 0;
    right: 50%;
    transition: all .2s;
  }
  .cid-rtVAO5iUaj .collapsed.navbar-expand {
    flex-direction: column;
  }
  .cid-rtVAO5iUaj .collapsed .btn {
    display: flex;
  }
  .cid-rtVAO5iUaj .collapsed .navbar-collapse {
    display: none !important;
    padding-right: 0 !important;
  }
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.collapsing,
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.show {
    display: block !important;
  }
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.collapsing .navbar-nav,
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.show .navbar-nav {
    display: block;
    text-align: center;
  }
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item,
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.show .navbar-nav .nav-item {
    clear: both;
  }
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.collapsing .navbar-buttons,
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.show .navbar-buttons {
    text-align: center;
  }
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child,
  .cid-rtVAO5iUaj .collapsed .navbar-collapse.show .navbar-buttons:last-child {
    margin-bottom: 1rem;
  }
  .cid-rtVAO5iUaj .collapsed button.navbar-toggler {
    display: block;
  }
  .cid-rtVAO5iUaj .collapsed .navbar-brand {
    margin-left: 1rem !important;
  }
  .cid-rtVAO5iUaj .collapsed .navbar-toggleable-sm {
    flex-direction: column;
    -webkit-flex-direction: column;
  }
  .cid-rtVAO5iUaj .collapsed .dropdown .dropdown-menu {
    width: 100%;
    text-align: center;
    position: relative;
    opacity: 0;
    display: block;
    height: 0;
    visibility: hidden;
    padding: 0;
    transition-duration: .5s;
    transition-property: opacity,padding,height;
  }
  .cid-rtVAO5iUaj .collapsed .dropdown.open > .dropdown-menu {
    position: relative;
    opacity: 1;
    height: auto;
    padding: 1.4rem 0;
    visibility: visible;
  }
  .cid-rtVAO5iUaj .collapsed .dropdown .dropdown-submenu {
    left: 0;
    text-align: center;
    width: 100%;
  }
  .cid-rtVAO5iUaj .collapsed .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
    margin-top: 0;
    position: inherit;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .3em;
    vertical-align: middle;
    content: "";
    border-top: .30em solid;
    border-right: .30em solid transparent;
    border-left: .30em solid transparent;
  }
  @media (max-width: 991px) {
    .cid-rtVAO5iUaj .navbar-expand {
      flex-direction: column;
    }
    .cid-rtVAO5iUaj img {
      height: 3.8rem !important;
    }
    .cid-rtVAO5iUaj .btn {
      display: flex;
    }
    .cid-rtVAO5iUaj button.navbar-toggler {
      display: block;
    }
    .cid-rtVAO5iUaj .navbar-brand {
      margin-left: 1rem !important;
    }
    .cid-rtVAO5iUaj .navbar-toggleable-sm {
      flex-direction: column;
      -webkit-flex-direction: column;
    }
    .cid-rtVAO5iUaj .navbar-collapse {
      display: none !important;
      padding-right: 0 !important;
    }
    .cid-rtVAO5iUaj .navbar-collapse.collapsing,
    .cid-rtVAO5iUaj .navbar-collapse.show {
      display: block !important;
    }
    .cid-rtVAO5iUaj .navbar-collapse.collapsing .navbar-nav,
    .cid-rtVAO5iUaj .navbar-collapse.show .navbar-nav {
      display: block;
      text-align: center;
    }
    .cid-rtVAO5iUaj .navbar-collapse.collapsing .navbar-nav .nav-item,
    .cid-rtVAO5iUaj .navbar-collapse.show .navbar-nav .nav-item {
      clear: both;
    }
    .cid-rtVAO5iUaj .navbar-collapse.collapsing .navbar-buttons,
    .cid-rtVAO5iUaj .navbar-collapse.show .navbar-buttons {
      text-align: center;
    }
    .cid-rtVAO5iUaj .navbar-collapse.collapsing .navbar-buttons:last-child,
    .cid-rtVAO5iUaj .navbar-collapse.show .navbar-buttons:last-child {
      margin-bottom: 1rem;
    }
    .cid-rtVAO5iUaj .dropdown .dropdown-menu {
      width: 100%;
      text-align: center;
      position: relative;
      opacity: 0;
      display: block;
      height: 0;
      visibility: hidden;
      padding: 0;
      transition-duration: .5s;
      transition-property: opacity,padding,height;
    }
    .cid-rtVAO5iUaj .dropdown.open > .dropdown-menu {
      position: relative;
      opacity: 1;
      height: auto;
      padding: 1.4rem 0;
      visibility: visible;
    }
    .cid-rtVAO5iUaj .dropdown .dropdown-submenu {
      left: 0;
      text-align: center;
      width: 100%;
    }
    .cid-rtVAO5iUaj .dropdown .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
      margin-top: 0;
      position: inherit;
      right: 0;
      top: 50%;
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .3em;
      vertical-align: middle;
      content: "";
      border-top: .30em solid;
      border-right: .30em solid transparent;
      border-left: .30em solid transparent;
    }
  }
  @media (min-width: 767px) {
    .cid-rtVAO5iUaj .menu-logo {
      flex-shrink: 0;
    }
  }
  .cid-rtVAO5iUaj .navbar-collapse {
    flex-basis: auto;
  }
  .cid-rtVAO5iUaj .nav-link:hover,
  .cid-rtVAO5iUaj .dropdown-item:hover {
    color: #f98533 !important;
  }