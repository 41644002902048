.cid-rwzoJR0xbC {
    padding-top: 30px;
    padding-bottom: 60px;
    background-color: #ffffff;
}
.cid-rwzoJR0xbC .mbr-slider .carousel-control {
    background: #1b1b1b;
}
.cid-rwzoJR0xbC .mbr-slider .carousel-control-prev {
    left: 0;
    margin-left: 2.5rem;
}
.cid-rwzoJR0xbC .mbr-slider .carousel-control-next {
    right: 0;
    margin-right: 2.5rem;
}
.cid-rwzoJR0xbC .mbr-slider .modal-body .close {
    background: #1b1b1b;
}
.cid-rwzoJR0xbC .mbr-gallery-item > div::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #554346;
    opacity: 0;
    -webkit-transition: 0.2s opacity ease-in-out;
    transition: 0.2s opacity ease-in-out;
    background: linear-gradient(to left, #554346, #45505b) !important;
}
.cid-rwzoJR0xbC .mbr-gallery-item > div:hover .mbr-gallery-title::before {
    background: transparent !important;
}
.cid-rwzoJR0xbC .mbr-gallery-item > div:hover:before {
    opacity: 0.7 !important;
}
.cid-rwzoJR0xbC .mbr-gallery-title {
    font-size: .9em;
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    padding: 1rem;
    color: #fff;
    z-index: 2;
}
.cid-rwzoJR0xbC .mbr-gallery-title:before {
    content: " ";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    background: #554346 !important;
    opacity: 0.7;
    -webkit-transition: 0.2s background ease-in-out;
    transition: 0.2s background ease-in-out;
    background: linear-gradient(to left, #554346, #45505b) !important;
}
.cid-rwzkP9v3e9 {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #ffffff;
}
.cid-rwzkP9v3e9 .mbr-section-subtitle {
    color: #767676;
}
.cid-rwzkP9v3e9 .media-row {
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
}
.cid-rwzkP9v3e9 .team-item {
    transition: all .2s;
    margin-bottom: 2rem;
}
.cid-rwzkP9v3e9 .team-item .item-image img {
    width: 100%;
}
.cid-rwzkP9v3e9 .team-item .item-name p {
    margin-bottom: 0;
}
.cid-rwzkP9v3e9 .team-item .item-role p {
    margin-bottom: 0;
}
.cid-rwzkP9v3e9 .team-item .item-social {
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: center;
}
.cid-rwzkP9v3e9 .team-item .item-social .socicon {
    color: #232323;
    font-size: 17px;
}
.cid-padding-content {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}
