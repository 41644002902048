.cid-rxHA4h33sg {
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #ffffff;
  }
  .cid-rxHA4h33sg .mbr-iconfont {
    font-size: 48px;
    padding-right: 1rem;
  }
  .cid-rxHA4h33sg .icon-block {
    margin-bottom: 10px;
  }
  .cid-rxHA4h33sg .icon-block .icon-block__icon {
    display: inline-block;
    vertical-align: middle;
  }
  .cid-rxHA4h33sg .icon-block .icon-block__title {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    line-height: 1;
    font-style: italic;
  }
  .cid-rxHA4h33sg .mbr-text {
    color: #767676;
  }
  .cid-rxHA4h33sg textarea.form-control {
    min-height: 100px;
  }
  .cid-rxHA4h33sg a:not([href]):not([tabindex]) {
    color: #fff;
    border-radius: 3px;
  }
  .cid-rxHA4h33sg .google-map {
    height: 30rem;
    position: relative;
  }
  .cid-rxHA4h33sg .google-map iframe {
    height: 100%;
    width: 100%;
  }
  .cid-rxHA4h33sg .google-map [data-state-details] {
    color: #6b6763;
    font-family: Montserrat;
    height: 1.5em;
    margin-top: -0.75em;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
  }
  .cid-rxHA4h33sg .google-map[data-state] {
    background: #e9e5dc;
  }
  .cid-rxHA4h33sg .google-map[data-state="loading"] [data-state-details] {
    display: none;
  }
  @media (max-width: 767px) {
    .cid-rxHA4h33sg h2 {
      padding-top: 2rem;
    }
    .cid-rxHA4h33sg .google-map iframe {
      height: 100%;
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    .cid-rxHA4h33sg .container > .row > .col-md-6:first-child {
      padding-right: 30px;
    }
    .cid-rxHA4h33sg .container > .row > .col-md-6:last-child {
      padding-left: 30px;
    }
    .cid-rxHA4h33sg .google-map iframe {
      height: 115%;
      width: 100%;
    }
  }

  @media (max-width: 744px) {
    .cid-rxHA4h33sg .container > .row > .col-md-6:first-child {
      padding-right: 30px;
      padding-bottom: 70px
    }
    .cid-rxHA4h33sg .container > .row > .col-md-6:last-child {
      padding-left: 30px;
    }
    .cid-rxHA4h33sg .google-map iframe {
      height: 115%;
      width: 100%;
    }
    .cid-rxHA4h33sg .container > .row > .name {
      padding-bottom: 30px;
    }
    .name {
      padding-bottom: 50px;
    }
  }